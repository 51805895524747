import { useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { useLocation, useParams } from 'react-router-dom'

import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import Button from '@/presentation/components/Button'
import Carousel from '@/presentation/components/Carousel'
import ImageItem from '@/presentation/components/Carousel/components/ImageItem'
import VideoItem from '@/presentation/components/Carousel/components/VideoItem'
import CircleStatus from '@/presentation/components/CircleStatus'
import FullscreenViewer from '@/presentation/components/FullscreenViewer'
import Header from '@/presentation/components/Header'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode, {
  ILocationState
} from '@/services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '@/services/providers/AlterationContext'
import { useBuilding } from '@/services/providers/BuildingContext'
import { Status, useDivergence } from '@/services/providers/DivergenceContext'
import {
  InspectionType,
  useInspection
} from '@/services/providers/InspectionContext'
import { Photo, useRoom, Video } from '@/services/providers/RoomContext'

const BuildingDetailsPage: React.FC = () => {
  const [showFullscreenViewer, setShowFullscreenViewer] = useState(false)
  const [fullscreenViewerIndex, setFullscreenViewerIndex] = useState(0)
  const [fullscreenViewerPhotos, setFullscreenViewerPhotos] = useState<Photo[]>(
    []
  )
  const [fullscreenViewerVideos, setFullscreenViewerVideos] = useState<Video[]>(
    []
  )

  const carouselRef = useRef<ResponsiveCarousel>(null)

  const { t } = useTranslation()

  const { orderCode } = useParams()
  const { pathname } = useLocation()
  const { state } = useLocation()
  const { alteration } = state as ILocationState

  const navigate = useNavigateWithOrderCode()

  const { building } = useBuilding()
  const { status } = useDivergence()
  const { startNewAlteration } = useAlteration()
  const { inspection } = useInspection()
  const { handlePutVisitedLabelInRoomItem } = useRoom()

  const useDamages =
    inspection?.inspectionType === InspectionType.SAIDA ||
    inspection?.inspectionType === InspectionType.CONFERENCIA

  const carouselPhotos = useMemo(() => {
    if (!building?.photos?.length) return []

    return building?.photos.map(photo => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [building?.photos])

  const carouselDamagePhotos = useMemo(() => {
    if (!building?.damagesPhotos?.length) return []

    return building?.damagesPhotos.map(photo => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [building?.damagesPhotos])

  const carouselVideos = useMemo(() => {
    if (!building?.videos?.length) return []

    return building?.videos.map(photo => (
      <VideoItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [building?.videos])

  const carouselDamageVideos = useMemo(() => {
    if (!building?.damagesVideos?.length) return []

    return building?.damagesVideos.map(photo => (
      <VideoItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [building?.damagesVideos])

  const handleOpenFullscreenViewer = (index: number) => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = 'blur(4px)'
      rootElement.style.position = 'absolute'
      rootElement.style.inset = '0'
    }

    setFullscreenViewerPhotos(
      useDamages ? building?.damagesPhotos || [] : building?.photos || []
    )
    setFullscreenViewerVideos(() => {
      const videos = useDamages
        ? building?.damagesVideos || []
        : building?.videos || []

      return videos.map(video => ({ ...video, isVideo: true }))
    })
    setFullscreenViewerIndex(index)
    setShowFullscreenViewer(true)
  }

  const handleCloseFullscreenViewer = () => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = ''
      rootElement.style.position = ''
      rootElement.style.inset = ''
    }

    setShowFullscreenViewer(false)
  }

  const handleNewAlteration = () => {
    startNewAlteration('0', 'building')

    navigate(ROUTES.ALTERATION_FORM)
  }

  useEffect(() => {
    carouselRef.current?.moveTo(0)

    return () => {
      handleCloseFullscreenViewer()
    }
  }, [pathname])

  useEffect(() => {
    if (!orderCode) return

    handlePutVisitedLabelInRoomItem(orderCode, '0', 'building')
  }, [orderCode])

  const carouselContent = useDamages
    ? [...carouselDamageVideos, ...carouselDamagePhotos]
    : [...carouselVideos, ...carouselPhotos]

  const fullscreenViewerContent = [
    ...fullscreenViewerVideos,
    ...fullscreenViewerPhotos
  ]

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header previousRoute={ROUTES.ROOMS} title={t('GENERAL', 'Geral')} />

      <div className="px-4 w-full max-w-[1304px]">
        <div className="flex flex-wrap gap-x-12 justify-between items-start my-6 lg:flex-nowrap">
          <Carousel
            handleCarouselItemClick={handleOpenFullscreenViewer}
            ref={carouselRef}
          >
            {carouselContent}
          </Carousel>

          <section className="flex-1">
            {alteration && (
              <Button
                className="flex justify-between items-center mt-4 mb-8 w-full"
                onClick={handleNewAlteration}
                colored
              >
                <span className="flex-1 text-center">
                  {t('ADD_CHANGE', 'Adicionar alteração')}
                </span>

                <svg
                  width="14"
                  height="14"
                  transform="scale(-1 1)"
                  viewBox="0 0 12 20"
                  fill="none"
                >
                  <path
                    d="M11 1L2 10L3 11L11 19"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              </Button>
            )}

            <h3 className="mb-6 text-xl text-gray-500">
              {t('OBSERVATIONS', 'Observações')}
            </h3>

            {useDamages ? (
              building?.damages?.length ? (
                building?.damages?.map((damage, i) => (
                  <div
                    key={i}
                    className="flex flex-wrap gap-2 justify-between items-start mb-4 last-of-type:mb-6 sm:flex-nowrap"
                  >
                    <span className="flex-1 text-gray-500">
                      {damage?.description}
                    </span>

                    {inspection?.inspectionType ===
                      InspectionType.CONFERENCIA && (
                      <div className="flex gap-1 items-center">
                        <span className="text-gray-500">
                          {t('SITUATION', 'Situação')} -{' '}
                          {!damage?.resolved && t('NO', 'Não')}{' '}
                          {t('RESOLVED', 'Resolvido')}
                        </span>

                        <CircleStatus
                          color={damage?.resolved ? '#059669' : '#CF0000'}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <span className="block mb-6 text-gray-500">
                  {t('NO_OBSERVATIONS_RECORDED', 'Nenhuma observação anotada')}
                </span>
              )
            ) : (
              <span className="block mb-6 text-gray-500">
                {building?.observation ||
                  t('NO_OBSERVATIONS_RECORDED', 'Nenhuma observação anotada')}
              </span>
            )}

            {inspection?.inspectionType !== 'predial' && (
              <>
                {status === Status.EXPIRED ? (
                  <div className="mb-4">
                    <div className="flex gap-2 items-center">
                      <CircleInfo color="#CF0000" className="shrink-0" />

                      <span className="text-error">
                        {t(
                          'CONTESTATION_EXPIRED',
                          'Prazo para contestação expirado'
                        )}
                      </span>
                    </div>

                    <div>
                      <span className="text-sm text-gray-500">
                        {t(
                          'CONTACT_ADMINISTRATOR',
                          'Entre em contato com a administradora para habilitar as contestações da vistoria novamente.'
                        )}
                      </span>
                    </div>
                  </div>
                ) : status === Status.UNAVAILABLE ? (
                  <div className="flex gap-2 items-center mb-4">
                    <CircleInfo color="#6b7280" className="shrink-0" />

                    <span className="text-gray-500">
                      {t(
                        'CONTESTATION_NOT_ALLOWED',
                        'Contestação não permitida no momento'
                      )}
                    </span>
                  </div>
                ) : (
                  status === Status.ALREADY_SENT && (
                    <div className="flex gap-2 items-center mb-4">
                      <CircleInfo color="#6b7280" className="shrink-0" />

                      <span className="text-gray-500">
                        {t(
                          'CONTESTATION_ALREADY_DONE',
                          'Contestação já realizada'
                        )}
                      </span>
                    </div>
                  )
                )}
              </>
            )}
          </section>
        </div>
      </div>

      {showFullscreenViewer && (
        <FullscreenViewer
          startIndex={fullscreenViewerIndex}
          content={fullscreenViewerContent}
          onClose={handleCloseFullscreenViewer}
        />
      )}
    </div>
  )
}

export default BuildingDetailsPage
