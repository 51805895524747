import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { resizePhoto } from '@/infra/files/resizePhoto'
import { alterationsStorageKey } from '@/services/constants/localStorageKeys'
import { useUploadImage } from '@/services/hooks/useUploadImage'
import { PhotoWithFilePath, UploadPhotoError } from '@/services/providers/types'
import { retry } from '@/services/utils/retry'

export type Alteration = {
  room_id: string | '0'
  room_name: string
  item_id: string | 'building' | 'general'
  item_name: string
  done: boolean
  can_be_updated?: boolean
  solicitation_group?: string
  related_solicitations?: Alteration[]
  description?: string
  status?: string
  comment?: string
  revert_changes?: string
  responsible_for_analysis?: string
  updated_on?: string
  photos: PhotoWithFilePath[]
  created_at?: string
  id?: number
}

export interface AlterationContextStore {
  imageAlterationUploadUrl: string
  setAlterationImageUploadUrl: Dispatch<React.SetStateAction<string>>
  orderId: string
  setOrderId: Dispatch<React.SetStateAction<string>>
  propertyId: string
  setPropertyId: Dispatch<React.SetStateAction<string>>
  alterations: Alteration[]
  setAlterations: Dispatch<React.SetStateAction<Alteration[]>>
  pendingAlterations: Alteration[]
  setPendingAlterations: Dispatch<React.SetStateAction<Alteration[]>>
  alterationInProcess?: Alteration
  startNewAlteration: (
    room_id: string,
    item_id: string,
    solicitation_group?: string
  ) => void
  makeAlteration: (alteration: Alteration) => Promise<UploadPhotoError | void>
  removeAlterationPhotoByFilePath: (filePath: string) => void
  removeAlteration: (room_id: string, item_id: string) => void
  getStoredPendingAlterationByOrderCode: (initialCode: string) => void
}

const AlterationContext = createContext<AlterationContextStore>(
  {} as AlterationContextStore
)

AlterationContext.displayName = 'AlterationContext'

interface AlterationProviderProps {
  children: React.ReactNode
}

export const AlterationProvider = ({ children }: AlterationProviderProps) => {
  const [orderCode, setOrderCode] = useState('')
  const [imageAlterationUploadUrl, setAlterationImageUploadUrl] = useState('')
  const [alterationInProcess, setAlterationInProcess] = useState<Alteration>()
  const [alterations, setAlterations] = useState<Alteration[]>([])
  const [pendingAlterations, setPendingAlterations] = useState<Alteration[]>([])
  const [orderId, setOrderId] = useState<string>('')
  const [propertyId, setPropertyId] = useState<string>('')

  const handleUploadImage = useUploadImage(imageAlterationUploadUrl)

  const getStoredPendingAlterationByOrderCode = useCallback(
    (initialCode: string) => {
      setOrderCode(initialCode)

      const key = alterationsStorageKey(initialCode)
      const localStoredData = localStorage.getItem(key)
      const parsedLocalStoredData = JSON.parse(localStoredData || '{}') || null

      if (
        parsedLocalStoredData?.pendingAlterations &&
        parsedLocalStoredData?.pendingAlterations.length
      ) {
        setPendingAlterations(state => {
          const stateAlterationIdentifiers = new Set(
            state.map(alteration => {
              return `${alteration.room_id} - ${alteration.item_id}`
            })
          )
          const mergedAlterations = [
            ...state,
            ...parsedLocalStoredData.pendingAlterations.filter(
              (storedAlteration: Alteration) => {
                return !stateAlterationIdentifiers.has(
                  `${storedAlteration.room_id} - ${storedAlteration.item_id}`
                )
              }
            )
          ]

          return mergedAlterations
        })
      }
    },
    []
  )

  useEffect(() => {
    if (!orderCode) return

    const key = alterationsStorageKey(orderCode)
    const updatedData = { pendingAlterations }

    localStorage.setItem(key, JSON.stringify(updatedData))
  }, [pendingAlterations, orderCode])

  const startNewAlteration = useCallback(
    (room_id: string, item_id: string, solicitation_group = '') => {
      const alreadyStartedAlteration = pendingAlterations.find(alteration => {
        return (
          alteration?.room_id === room_id && alteration?.item_id === item_id
        )
      })

      if (alreadyStartedAlteration) {
        setAlterationInProcess(alreadyStartedAlteration)
        return
      }

      setAlterationInProcess({
        room_id,
        room_name: '',
        item_id,
        item_name: '',
        done: false,
        photos: [],
        description: '',
        solicitation_group: solicitation_group
      })
    },
    [pendingAlterations]
  )

  const makeAlteration = useCallback(
    async (newAlteration: Alteration) => {
      let uploadedPhotos: PhotoWithFilePath[] = []

      if (newAlteration?.photos?.length) {
        const uploadPromises = newAlteration.photos
          .filter(photo => !photo?.hasRemotelyUploaded)
          .map(async photo => {
            try {
              const { resizedPhoto, url } = await resizePhoto(photo?.file)

              const filePath = await retry(3, () => {
                return handleUploadImage(photo.id, resizedPhoto)
              })

              if (url) URL.revokeObjectURL(url)

              return {
                id: photo.id,
                filePath,
                file: resizedPhoto,
                hasRemotelyUploaded: !!filePath
              }
            } catch (err) {
              console.error(
                'AlterationContext.makeAlteration upload photo error',
                err
              )

              return {
                id: photo.id,
                filePath: '',
                file: null as unknown as File,
                hasRemotelyUploaded: false
              }
            }
          })

        uploadedPhotos = await Promise.all(uploadPromises)

        if (uploadedPhotos.some(photo => !photo?.hasRemotelyUploaded)) {
          return true
        }
      }

      const successfullyUploadedPhotos = uploadedPhotos.filter(photo => {
        return !!photo?.filePath
      })

      const alterationAlreadyExists = pendingAlterations.some(
        existingAlteration => {
          return (
            existingAlteration?.room_id === newAlteration?.room_id &&
            existingAlteration?.item_id === newAlteration?.item_id
          )
        }
      )

      if (!alterationAlreadyExists) {
        setPendingAlterations(state => [
          ...state,
          { ...newAlteration, photos: successfullyUploadedPhotos }
        ])
        return
      }

      setPendingAlterations(state => {
        return state.map(existingAlteration => {
          if (
            existingAlteration?.room_id !== newAlteration?.room_id ||
            existingAlteration?.item_id !== newAlteration?.item_id
          ) {
            return existingAlteration
          }

          return {
            ...existingAlteration,
            ...newAlteration,
            photos: [
              ...existingAlteration.photos,
              ...successfullyUploadedPhotos
            ]
          }
        })
      })

      setAlterationInProcess(undefined)
    },
    [pendingAlterations, imageAlterationUploadUrl]
  )

  const removeAlterationPhotoByFilePath = useCallback((filePath: string) => {
    setPendingAlterations(state => {
      return state.map(alteration => ({
        ...alteration,
        photos: alteration?.photos.filter(photo => {
          return photo?.filePath !== filePath
        })
      }))
    })

    setAlterationInProcess(state => {
      const alterationWithoutPhoto = {
        ...state,
        photos: state?.photos.filter(photo => {
          return photo?.filePath !== filePath
        })
      } as Alteration

      return alterationWithoutPhoto
    })
  }, [])

  const removeAlteration = useCallback((room_id: string, item_id: string) => {
    setPendingAlterations(state => {
      const itemToRemove = state.find(
        obj => obj.room_id === room_id && obj.item_id === item_id
      )
      return state.filter(alteration => {
        return alteration !== itemToRemove
      })
    })
  }, [])

  return (
    <AlterationContext.Provider
      value={{
        imageAlterationUploadUrl,
        setAlterationImageUploadUrl,
        alterations,
        setAlterations,
        pendingAlterations,
        setPendingAlterations,
        alterationInProcess,
        startNewAlteration,
        orderId,
        setOrderId,
        propertyId,
        setPropertyId,
        makeAlteration,
        removeAlterationPhotoByFilePath,
        removeAlteration,
        getStoredPendingAlterationByOrderCode
      }}
    >
      {children}
    </AlterationContext.Provider>
  )
}

export const useAlteration = () => {
  const context = useContext(AlterationContext)

  if (!context) {
    throw new Error('useAlteration must be used within an AlterationProvider')
  }

  return context
}
